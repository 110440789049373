import { LocationData } from 'app/components/common/locationBlock/LocationBlock.d';
import { FlowState } from 'app/contexts/store/reducer';
import { RescheduleBooking } from 'app/pages/manageBookings/ManageBookings';
import { CurrentServiceInfo, ProfileConnectTransactionType } from 'app/routes';
import { Suburb } from 'app/services/api/external/suburbSearch/SuburbSearch';

type StorageType = 'session' | 'local';

type AllowedItems = {
  isReminderSent: boolean; // Checks if a reminder has been sent. Will redirect to send reminder if not and is refreshed when the send reminder page is loaded.
  isCsr: boolean; // Stored in session storage to indicate if a person is a CSR, this is triggered in the landing page through adding /csr to the route
  nextPath: string; // Stored in session storage to record the next route after the user returns from profile connect
  lastPath: string; // Stored in session storage to record the previous route before redirecting to profile connect
  currentPath: string; // Stored in session storage to record the current path, this is used to prevent user from skipping steps through manually changing URL, it's also used so refreshing a page doesn't count as skipping steps
  accessToken: string; // Stored in session storage to retreive profile connect user profile, it's retreived from the above mentioned external user login process
  idToken: string; // Stored in local storage to do a variety of authentication, it's retreived from the above mentioned external user login process
  sid: string; // Stored in local storage to retreive biz connect user profile, it's retreived from the above mentioned external user login process
  homePath: string; // Stored in session storage to record the home path for later redirect on pages like 404
  userLoginTimestamp: string; // Stored in session storage to help calculating session timeout
  userInfo: string; // Stored in session storage for AuthContext
  isAuthenticated: boolean; // Stored in session storage for AuthContext
  flowState: FlowState; // Stored in session storage to record the bulk of form data, for detailed breakdown of what exactly is stored in this json, checkout flowStateContext
  lastPathForMaintenance: string; // Stored in session storage to record the previous route before redirecting to maintenance
  hasGivenConsent: boolean; // Stored in session storage to record if the user has given consent for us to collect information
  currentService: string; // Stored in session storage to record the name of the current service
  currentServiceInfo: CurrentServiceInfo; // Stored in session storage to record the more complex service data, add more into this JSON as needed
  userGeolocation: GeolocationCoordinates; // Stored in session storage to record user's geo location coordinates
  searchedSuburb: Suburb; // Stored in session storage to record the suburb which the user searched for service centre
  selectedServiceLocation: Partial<LocationData>; // Stored in session storage to record the location which the user selected
  bookableInAdvance: number; // Stored in session storage to record the allowed bookable days in advance for a service
  recaptchaToken: string; // short life recaptcha token from google, prefetched and stored for performance
  reschedule: RescheduleBooking; // stored in session storage to identify booking reschedule workflow
  profileConnectTransactionType: ProfileConnectTransactionType; // stored in session storage to identify profile connect transaction type
  postcode: string; // stored in session storage to record chosen postcode of service centre from external site
  URLRetries: Record<string, number>; // stored in session storage to record the number of retries if a 429 is ever recieved
};

type Subscription<K extends keyof AllowedItems> = (newValue: AllowedItems[K]) => void;

const subscriptions: { [key: string]: Subscription<any>[] } = {};

export const getItemNameWithServicePrefix = (itemName: string): string => {
  // If currentService is not set, the prefix is not added. This is important for tests as there won't be currentService there
  // DO NOT USE ANY VARIABLE NAME CONTAINING -, - is reserved to indicate a service scoped variable!
  const servicePrefix = JSON.parse(sessionStorage.getItem('currentService'))
    ? `${JSON.parse(sessionStorage.getItem('currentService'))}-`
    : '';
  // These two variables are not service-scoped, they shouldn't have a service prefix
  if (
    ![
      'currentService',
      'currentServiceInfo',
      'currentPath',
      'isAuthenticated',
      'userLoginTimestamp',
      'userInfo',
      'accessToken',
      'idToken',
    ].includes(itemName)
  ) {
    return servicePrefix + itemName;
  } else {
    return itemName;
  }
};

export const getStorageItem = <K extends keyof AllowedItems>(
  itemName: K,
  storageType: StorageType = 'session',
): AllowedItems[K] => {
  const itemNameWithServicePrefix = getItemNameWithServicePrefix(itemName);
  if (storageType === 'session') {
    const stringifiedValue = sessionStorage.getItem(itemNameWithServicePrefix);
    return JSON.parse(stringifiedValue) as AllowedItems[K];
  } else {
    const stringifiedValue = localStorage.getItem(itemNameWithServicePrefix);
    return JSON.parse(stringifiedValue) as AllowedItems[K];
  }
};

export const setStorageItem = <K extends keyof AllowedItems>(
  itemName: K,
  itemValue: AllowedItems[K],
  storageType: StorageType = 'session',
): void => {
  const stringifiedValue = JSON.stringify(itemValue);
  const itemNameWithServicePrefix = getItemNameWithServicePrefix(itemName);
  if (storageType === 'session') {
    sessionStorage.setItem(itemNameWithServicePrefix, stringifiedValue);
  } else {
    localStorage.setItem(itemNameWithServicePrefix, stringifiedValue);
  }
  subscriptions[itemNameWithServicePrefix]?.map((callback) => {
    callback(itemValue);
  });
};

export const subscribeToStorageItem = (itemName: keyof AllowedItems, onChange: Subscription<typeof itemName>) => {
  const itemNameWithServicePrefix = getItemNameWithServicePrefix(itemName);
  subscriptions[itemNameWithServicePrefix] = [...(subscriptions[itemNameWithServicePrefix] ?? []), onChange];
};

export const unSubscribeToStorageItem = (itemName: keyof AllowedItems, onChange: Subscription<typeof itemName>) => {
  const itemNameWithServicePrefix = getItemNameWithServicePrefix(itemName);
  subscriptions[itemNameWithServicePrefix] = (subscriptions[itemNameWithServicePrefix] ?? []).filter(
    (subscription) => subscription !== onChange,
  ) as Subscription<typeof itemName>[];
};

export const clearAllSubscriptions = Object.keys(subscriptions).map((key) => {
  delete subscriptions[key];
});

export const removeStorageItem = (itemName: keyof AllowedItems, storageType: StorageType = 'session'): void => {
  const itemNameWithServicePrefix = getItemNameWithServicePrefix(itemName);
  if (storageType === 'session') {
    sessionStorage.removeItem(itemNameWithServicePrefix);
  } else {
    localStorage.removeItem(itemNameWithServicePrefix);
  }
};
export const skipClearStorage = (key: string): boolean => {
  // bookableInAdvance && profileConnectTransactionType are service specific keys
  // that are set in each page load (router) and should not be cleared in the landing page
  const skip = ['bookableInAdvance', 'profileConnectTransactionType'];
  for (const skipKey of skip) {
    if (key.includes(skipKey)) return true;
  }
  return false;
};
export const clearStorageItemsWithServicePrefix = (): void => {
  for (const key in sessionStorage) {
    if (key.includes('-') && !skipClearStorage(key)) sessionStorage.removeItem(key);
  }
  for (const key in localStorage) {
    if (key.includes('-') && !skipClearStorage(key)) localStorage.removeItem(key);
  }
};
