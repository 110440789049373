import { Suspense, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useLocation, useHistory } from 'react-router-dom';
import { ComponentLoader, GlobalStyle } from '@snsw-gel/react';
import { setGlobalNavUri, GlobalNav } from '@snsw/react-global-nav';
import { commonRoutes, ERoutesPathCommon } from 'app/routes/common/routes';
import { Route as RouteProps, DynamicRouteProps } from 'app/routes/types';
import PrivateRoute from 'app/sharedServices/authentication/PrivateRoute';
import DynamicFormHeader from 'app/components/layout/DynamicFormHeader';
import { getAllRoutes } from 'app/services/routes/RouteService';
import FlowStateContext from 'app/contexts/FlowStateContext';
import { getServiceInfo } from 'app/services/api/internal/API';
import {
  getStorageItem,
  setStorageItem,
  subscribeToStorageItem,
  unSubscribeToStorageItem,
} from 'app/sharedServices/storage/storage';
import { extractDataOrOpenErrorModal } from 'app/services/api/dataExtraction';
import ServiceNotAvailable from 'app/pages/serviceNotAvailable/ServiceNotAvailable';
import { BookingTypeValues } from 'app/components/common/locationFilters/bookingTypeFilters/BookingTypeFilters';
import { RJSFFormData } from 'app/contexts/store/reducer';
import CustomNav from 'app/components/common/customNav/CustomNav';
import { AppContainer, GlobalNavStyles } from './index.styled';

export type CurrentServiceInfo = {
  id: string;
  initialServiceId?: string;
  initialServiceURLPath?: string;
  showInteractionType: boolean;
  guestBookingsAllowed: 'Yes' | 'No';
  sendCustomerSms: boolean;
  interactionTypes: BookingTypeValues[];
  useCustomNav: boolean;
  bookingPageMessage: string;
  thumbsUpThumbsDownId: string;
  customNavigationMeta?: CustomNavigationMeta;
};

type GlobalNavigationProps = {
  footer: 'default' | 'contact' | 'compressed';
  geolocation: boolean;
  translation: boolean;
  search: boolean;
  menu: boolean;
  showFooter: boolean;
  showAcknowledgement: boolean;
  showHeader: boolean;
};

export type CustomNavigationMeta = {
  coBranded: boolean;
  useCustomNav: boolean;
  logoImagePath: string;
  logoAltText: string;
  logoAnchorUrl: string;
} & Partial<GlobalNavigationProps>;

export type ProfileConnectTransactionType =
  | 'NAME_CONTACT_ADDRESS'
  | 'NAME_CONTACT'
  | 'FULLNAME_CONTACT_ADDRESS'
  | 'FULLNAME_CONTACT';

setGlobalNavUri(
  process.env.OBS_API_URL === 'http://localhost:3000/local'
    ? 'https://api-it1.g.testservicensw.net'
    : process.env.OBS_API_URL.replace('/obs', ''),
  { 'x-api-key': process.env.X_API_KEY },
);

const getRoute = (route: RouteProps, index: number, formData: Partial<RJSFFormData>) => {
  const Component: React.FC<DynamicRouteProps> = route.component;
  const routeProps = {
    basePath: route.basePath,
    path: route.path,
    exact: route.exact,
    page: route.page,
  };

  const routeRenderer = () => {
    return (
      <>
        <DynamicFormHeader route={route} />
        <Component page={route.page} />
      </>
    );
  };

  if (route.private && !formData?.isGuestBooking) {
    return <PrivateRoute key={index} render={routeRenderer} {...routeProps} />;
  } else {
    return <Route key={index} render={routeRenderer} {...routeProps} />;
  }
};

// globalnav doesn't build an anchor tag with a relative url only a root url. The following logic adds our base url to the 'skip to content' button.
const addRelativePathToSkipToContentButton = (pathname) => {
  const skipToContentButton = Array.from(document.getElementsByTagName('a')).find((element) =>
    element.getAttribute('href').endsWith('#main-content'),
  );
  skipToContentButton && skipToContentButton.setAttribute('href', `${pathname}#main-content`);
};

const ServiceRouter: React.FC = () => {
  const { formData } = useContext(FlowStateContext);
  const [serviceRoutes, setServiceRoutes] = useState<RouteProps[]>([]);
  const [serviceIsEnabled, setServiceIsEnabled] = useState<boolean>(true);
  const { service } = useParams<{ service: string }>();
  const history = useHistory();
  const location = useLocation();
  const isFirstPage = [`/services/${service}`, `/services/${service}/`].includes(location.pathname);
  const routeVariant = getStorageItem('reschedule', 'session');

  setStorageItem('currentService', service);

  const userShouldBeRedirectedHome = (currentPath: RouteProps): boolean => {
    // Redirect user to the landing page if they directly jumped to a later step
    if (currentPath && currentPath.stepOrder !== undefined) {
      const userHasGivenConsent = getStorageItem('hasGivenConsent');

      const isCsr = getStorageItem('isCsr', 'session');
      // Next path is checked here as it is removed at the end of profile connect listener
      // if this variable is present on the first page the user entered, it means they didn't
      // go through profile connect as intended
      const userCameFromProfileConnect =
        isCsr === false && [0, 1].includes(currentPath.stepOrder) && !getStorageItem('nextPath');
      const userCameFromPersonalDetailsPage = isCsr === true && [0, 1].includes(currentPath.stepOrder);
      const userSkippedStep = !userCameFromPersonalDetailsPage && !userCameFromProfileConnect;
      const userRefreshedPage = currentPath.path === getStorageItem('currentPath', 'session');
      return !userHasGivenConsent || (userSkippedStep && !userRefreshedPage);
    } else if (currentPath && !currentPath.path.includes('landing')) {
      return true;
    }
  };

  const pathNotExist = (currentPath: RouteProps): boolean => {
    const currentPathIsValid = currentPath || isFirstPage;
    return !currentPathIsValid;
  };

  const loadRoutes = async () => {
    const response = await getServiceInfo(service);
    const responseData = extractDataOrOpenErrorModal(response);

    if (responseData === 'error' || !responseData || !Object.keys(responseData).length) {
      history.push('/404');
      return;
    }

    setServiceIsEnabled(responseData.isEnabled);
    setStorageItem('bookableInAdvance', responseData.bookableInAdvanceInDays);
    setStorageItem('profileConnectTransactionType', responseData.profileConnectTransactionType);

    const landingPage = responseData.pages?.find((page) => page.path?.includes('landing'));
    const bookASlotPage = responseData.pages?.find((page) => page.path?.includes('book-a-slot'));

    if (bookASlotPage) {
      bookASlotPage.page.structure.content.serviceId = responseData.id;
      responseData.pages[responseData.pages.indexOf(bookASlotPage)] = bookASlotPage;
    }

    if (getStorageItem('reschedule', 'session')) {
      const reviewPage = responseData.pages?.find((page) => page.path?.includes('review'));
      const confirmationPage = responseData.pages?.find((page) => page.path?.includes('confirmation'));

      bookASlotPage.stepOrder = 1;
      bookASlotPage.page.navigation = { lastPath: '', nextPath: reviewPage.path };

      reviewPage.stepOrder = 2;
      reviewPage.page.navigation = { lastPath: bookASlotPage.path, nextPath: confirmationPage.path };
      const appointmentDetailsReview = reviewPage.page.structure.content.schema.properties.appointmentDetails;
      const showSendCustomerSms = reviewPage.page.structure.content.schema.properties.showSendCustomerSms;
      if (appointmentDetailsReview) {
        reviewPage.page.structure.content.schema.properties = {
          appointmentDetails: appointmentDetailsReview,
          showSendCustomerSms,
        };
      }

      responseData.pages = [bookASlotPage, reviewPage, confirmationPage];
    } else {
      const csrLandingPage = { ...landingPage };
      csrLandingPage.path = `${csrLandingPage.path}/csr`;
      responseData.pages.push(csrLandingPage);
    }

    const resolvedRoutes = [
      ...getAllRoutes([
        {
          basePath: `/services/${service}/`,
          title: responseData.name,
          routes: responseData.pages,
        },
      ]),
    ];

    const allRoutes = [...resolvedRoutes, ...commonRoutes];
    if (!serviceRoutes?.length) setServiceRoutes(allRoutes);
    setStorageItem('currentServiceInfo', {
      id: responseData.id,
      showInteractionType: responseData.showInteractionType,
      guestBookingsAllowed: responseData.guestBookingsAllowed,
      sendCustomerSms: responseData.sendCustomerSms,
      interactionTypes: responseData.interactionTypes,
      initialServiceId: responseData.initialServiceId,
      initialServiceURLPath: responseData.initialServiceURLPath,
      bookingPageMessage: responseData?.bookingPageMessage ?? '',
      useCustomNav: responseData.useCustomNav,
      thumbsUpThumbsDownId: responseData.thumbsUpThumbsDownId,
      customNavigationMeta: responseData.customNavigationMeta,
    });
  };

  const redirectIfNeeded = async () => {
    const isLogoutPage = location.pathname.includes('logout');

    if (serviceRoutes.length && !isLogoutPage) {
      const currentPath = serviceRoutes.find((serviceRoute) => serviceRoute.path === window.location.pathname);
      if (pathNotExist(currentPath)) {
        history.push('/404');
      } else if (userShouldBeRedirectedHome(currentPath)) {
        history.push(`/services/${service}/`);
      }
    }
  };

  useEffect(() => {
    loadRoutes();
  }, [routeVariant]);

  useEffect(() => {
    redirectIfNeeded();
  }, [serviceRoutes]);

  if (serviceRoutes.length) {
    setStorageItem('currentPath', location.pathname, 'session');
  }

  return serviceRoutes.length > 0 ? (
    serviceIsEnabled ? (
      <Switch>
        {serviceRoutes.map((route: RouteProps, index: number) => getRoute(route, index, formData))}
        {!!isFirstPage && <Redirect to={serviceRoutes[0].path} />}
      </Switch>
    ) : (
      <ServiceNotAvailable />
    )
  ) : (
    <ComponentLoader label="Loading" />
  );
};

const AppRouter: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const [navigationMeta, setNavigationMeta] = useState<CustomNavigationMeta>(undefined);
  const { formData, setFlow } = useContext(FlowStateContext);
  const { pathname } = useLocation();

  useEffect(() => {
    const flowDataAsString = getStorageItem('flowState', 'session');
    if (flowDataAsString) setFlow(flowDataAsString);
    setLoaded(true);
  }, []);

  useEffect(() => {
    addRelativePathToSkipToContentButton(pathname);
  }, [loaded, pathname]);

  useEffect(() => {
    const onChange = (currentServiceInfo) => {
      const { customNavigationMeta } = currentServiceInfo;
      if (customNavigationMeta) {
        const navigationMetaAdjustedForCustomBranding = {
          ...customNavigationMeta,
          ...((customNavigationMeta.useCustomNav
            ? { footer: 'compressed', search: false, menu: false }
            : {}) as Partial<CustomNavigationMeta>),
        };
        setNavigationMeta(navigationMetaAdjustedForCustomBranding);
      }
    };
    subscribeToStorageItem('currentServiceInfo', onChange);
    return () => unSubscribeToStorageItem('currentServiceInfo', onChange);
  }, [loaded]);

  return (
    <>
      {loaded && (
        <Suspense fallback={<ComponentLoader active={true} fullPage />}>
          {/* X_API_KEY */}
          <GlobalNavStyles customNav={navigationMeta?.useCustomNav}>
            <GlobalNav {...navigationMeta}>
              {navigationMeta?.useCustomNav && <CustomNav navigationMeta={navigationMeta} />}
              <GlobalStyle />
              <AppContainer id="main-content">
                <Switch>
                  <Route path="/services/:service" component={ServiceRouter} />
                  {commonRoutes.map((route: RouteProps, index: number) => getRoute(route, index, formData))}
                  <Redirect to={ERoutesPathCommon.HOME} />
                </Switch>
              </AppContainer>
            </GlobalNav>
          </GlobalNavStyles>
        </Suspense>
      )}
    </>
  );
};

export default AppRouter;
