import { createContext, useMemo } from 'react';
import { setFormDataState, setFlowState, setConfirmedBookingState, clearFlowState } from 'app/contexts/store/actions';
import { initialState, RJSFFormData, FlowState, useStore } from 'app/contexts/store/reducer';

interface IFlowStateContext extends FlowState {
  setFlow: (flowState: FlowState) => void;
  clearFlow: () => void;
  setFormData: (formData: Partial<RJSFFormData>) => void;
  setConfirmedBooking: (booking: CommonTypes.ConfirmedBooking) => void;
}

const FlowStateContext = createContext<Partial<IFlowStateContext>>(initialState);

export const FlowStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useStore();

  const setFlow = (flowState: Record<string, unknown>): void => {
    dispatch(setFlowState(flowState));
  };
  const setFormData = (formData: RJSFFormData): void => {
    dispatch(setFormDataState(formData));
  };
  const clearFlow = (): void => {
    dispatch(clearFlowState());
  };
  const setConfirmedBooking = (confirmedBooking: CommonTypes.ConfirmedBooking): void => {
    dispatch(setConfirmedBookingState(confirmedBooking));
  };

  const providerValue = useMemo(
    () => ({
      setFlow,
      setFormData,
      setConfirmedBooking,
      clearFlow,
      ...state,
    }),
    [setFlow, setFormData, setConfirmedBooking, clearFlow, { ...state }],
  );

  return <FlowStateContext.Provider value={providerValue}>{children}</FlowStateContext.Provider>;
};

export default FlowStateContext;
