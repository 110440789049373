import { lazy } from 'react';
import { Route } from 'app/routes/types';

export enum ERoutesPathCommon {
  AUTH_CALLBACK = '/auth/callback',
  HOME = '/',
  ERROR = '/404',
  LOGOUT = 'logout',
  MANAGE_BOOKINGS = '/manage-bookings',
  PROFILE_CONNECT_URL_CALLBACK = '/profile-connect/:status',
  UNDER_MAINTENANCE = '/under-maintenance',
  CATEGORY_WITH_AGENCY = '/categories/:category/:agency',
  CATEGORY = '/categories/:category',
  SEND_REMINDER = '/bookings-reminder',
}

const commonRoutesList: Route[] = [
  {
    component: lazy(() => import('app/pages/authenticate/Authenticate')),
    path: ERoutesPathCommon.AUTH_CALLBACK,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/error/404')),
    path: ERoutesPathCommon.ERROR,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/home/Home')),
    path: ERoutesPathCommon.HOME,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/home/Home')),
    path: '/services',
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/profileConnect/ProfileConnectListener')),
    path: '/profile-connect/:status',
    basePath: '/',
    private: true,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/manageBookings/ManageBookings')),
    path: ERoutesPathCommon.MANAGE_BOOKINGS,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/manageBookings/SendReminder')),
    path: ERoutesPathCommon.SEND_REMINDER,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/logout/Logout')),
    path: '/*/logout',
    private: false,
    basePath: '/',
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/logout/Logout')),
    path: '/logout',
    private: false,
    basePath: '/',
    exact: false,
    dynamic: false,
    stepper: false,
  },
  {
    component: lazy(() => import('app/pages/maintenance/UnderMaintenance')),
    path: ERoutesPathCommon.UNDER_MAINTENANCE,
    basePath: '/',
    private: false,
    exact: true,
    dynamic: false,
    stepper: false,
  },
  {
    /**
     * quickfix for SBR-2268
     * added a disabled feature to easily filter out the route in a non-destructive manner.
     *
     * We have no active category pages but don't know this until the result of an API call to fetch data from db.
     * By then we've already sent content so would need bigger changes before we could reply with 404
     * Will need a proper solution once we need a category page again.
     */
    component: lazy(() => import('app/pages/category/Category')),
    path: ERoutesPathCommon.CATEGORY_WITH_AGENCY,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
    disabled: true,
  },
  {
    component: lazy(() => import('app/pages/category/Category')),
    path: ERoutesPathCommon.CATEGORY,
    basePath: '/',
    private: false,
    exact: false,
    dynamic: false,
    stepper: false,
    disabled: true,
  },
];

export const commonRoutes: Route[] = commonRoutesList.filter((route: Route) => !route.disabled);
